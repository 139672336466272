import React, { useState } from 'react';
import Select, { components } from "react-select";
import "./SearchFilterDropdown.css";
import { AddSymbol } from "assets";

const customDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {/* Use your custom icon component here */}
      <AddSymbol size="20" color="blue" />
    </components.DropdownIndicator>
  );
};

const SearchFilterDropDown = ({ task, onChange, options }) => {
  const handleSelectChange = (selectedOption) => {
    onChange(selectedOption);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: "1px solid #DEDEDE",
      borderColor: "#ccc",
      boxShadow: "none",
      fontFamily: "AktivGrotesk",
      fontSize: "16px",
      fontWeight: "500",
      height: "50px",
      fontStyle: "normal",
    }),
    dropdownIndicator: (provided) => ({
      /* Your custom styles here */
      ...provided,
      marginTop: "-12px",
      marginRight: "6px",
    }),
    valueContainer: (provided) => ({
      /* Your custom styles here */
      ...provided,
      marginTop: "-8px",
    }),
    indicatorSeparator: (provided) => ({
      /* Your custom styles here */
      ...provided,
      display: "none",
    }),
  };

  return (
    <div>
      <Select
        className="select2"
        options={options}
        value={task}
        onChange={handleSelectChange}
        isSearchable={true}
        styles={customStyles}
        placeholder={"Choose Task"}
        noOptionsMessage={() => "No Task(s) available"}
      />
    </div>
  );
};

export default SearchFilterDropDown;
