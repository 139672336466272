import React, { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

const AuthenticatedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated === false) {
    window.location = `${process.env.REACT_APP_AUTH_URL}?next=${window.location.href}`;
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthenticatedRoute;
