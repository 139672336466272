import React, { useContext } from 'react';
import { Calendar, EntryForm, Header } from '../../components';
import { CalendarContext } from '../../contexts/CalendarContext';

import './Home.css';

function Home() {
  const {
    currentDate,
    updateCurrentDate,
    lockDate,
  } = useContext(CalendarContext);

  return (
    <React.Fragment>
      {currentDate && (
        <div className="row">
          <div id="leftside" className="col-12">
            <span className="calen">
              <Calendar
                currentDate={currentDate}
                month={currentDate.getMonth()}
                year={currentDate.getFullYear()}
                lockDate={lockDate}
                handleAddEntryClick={(date) => updateCurrentDate(date)}
              />
            </span>
          </div>
        </div>
      )}
      <EntryForm currentDate={currentDate} />
    </React.Fragment>
  );
}

export default Home;
