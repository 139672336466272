import connectionInstance from "../utils/connection";
export const baseurl = process.env.REACT_APP_API_URL;
export const url = `${process.env.REACT_APP_API_URL}/api`;
export const authUrl = process.env.REACT_APP_AUTH_API_URL;

export const getUserDetails = () =>
  connectionInstance.post(`${authUrl}/getCurrentUser/`, {});
export const getHolidayList = () =>
  connectionInstance.get(`${url}/holiday_list`);
export const getProjectList = () =>
  connectionInstance.get(`${url}/views/projects`);
export const getAllProjectList = (queryParams) =>
  connectionInstance.get(`${url}/views/projects/all`, { params: queryParams });
export const postProjectRequest = (data) =>
  connectionInstance.post(`${url}/views/projects/all`, data);
export const fetchKeyWords = () => connectionInstance.get(`${url}/keywords`);
export const getAllEntries = () => connectionInstance.get(`${url}/me/entries`);
export const getMonthEntries = (queryParams) =>
  connectionInstance.get(`${url}/me/entries/month`, { params: queryParams });
export const getFrequentEntries = (queryParams) =>
  connectionInstance.get(`${url}/me/entries/frequent`, { params: queryParams });
export const postProjectEntry = (data) =>
  connectionInstance.post(`${url}/me/entries`, data);
export const postLeaveEntry = (data) =>
  connectionInstance.post(`${url}/leaves`, data);
export const deleteEntry = (id) =>
  connectionInstance.delete(`${url}/me/entries/${id}`);
export const updateEntry = (id, data) =>
  connectionInstance.patch(`${url}/me/entries/${id}`, data);
export const updateLeaveEntry = (id, data) =>
  connectionInstance.patch(`${url}/leaves/${id}`, data);

export const getNewLockedDate = () =>
  connectionInstance.get(`${url}/locked_date`);
export const updateNewLockedDate = (data) =>
  connectionInstance.patch(`${url}/locked_date`, data);
export const getNewToken = () => connectionInstance.get(`${url}/token`);

export const postAllowEditRequest = (data) =>
  connectionInstance.post(`${url}/allow_access`, data);

export const getEntryRequestsCount = () =>
  connectionInstance.get(`${url}/entry-requests`);

// CSV DATA
export const getActiveUserData = () =>
  connectionInstance.get(`${baseurl}/exportform/csv/userdata/active`);
export const getCompleteUserData = () =>
  connectionInstance.get(`${baseurl}/exportform/csv/userdata`);
