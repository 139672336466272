import { TSButton } from 'components';
import React, { useEffect, useState } from 'react';
import { getNewLockedDate, updateNewLockedDate } from 'services/api';
import './ChangeLock.css';
import { useNavigate } from 'react-router-dom';

function ChangeLock() {
  const navigate = useNavigate()
  const [lockDate, setLockDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  useEffect(() => {
    getNewLockedDate()
      .then((res) => {
        if (res && res.data) {
          setLockDate(res.data.locked_date);
          setSelectedDate(res.data.locked_date);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleChangeSubmit = (e) => {
    e.preventDefault();
    const data = { lockedDate: selectedDate };
    updateNewLockedDate(data)
      .then((res) => {
        if (res && res.data) {
          setLockDate(selectedDate);
        }
        navigate('/')
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="row justify-content-center">
      <div className="col-3">
        <div className="card">
          <div className="card-body">
            <h3 className="header-3">Current Lock Date is: {lockDate}</h3>
            <form style={{ marginTop: '24px' }}>
              <span className="yy">
                <input
                  type="date"
                  id="datePicker"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </span>
              <div style={{ marginTop: '16px' }}>
                <TSButton
                  value="change"
                  label="CHANGE"
                  onClick={handleChangeSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeLock;
