import React from 'react';
import { Home } from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import { CalendarContextProvider } from "./contexts/CalendarContext";
import ChangeLock from "pages/ChangeLock/ChangeLock";
import AuthenticatedRoute from "utils/AuthenticatedRoute";
import NotFound from "pages/NotFound/NotFound";
import DisplayToken from "pages/DisplayToken/DisplayToken";
import CalendarLayout from 'layouts/CalendarLayout';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AuthProvider>
              <AuthenticatedRoute>
                <CalendarContextProvider>
                  <CalendarLayout>
                    <Home />
                  </CalendarLayout>
                </CalendarContextProvider>
              </AuthenticatedRoute>
            </AuthProvider>
          }
        />
        <Route
          path="/igntime"
          element={
            <AuthProvider>
              <AuthenticatedRoute>
                <CalendarContextProvider>
                  <CalendarLayout>
                  <Home />
                  </CalendarLayout>
                </CalendarContextProvider>
              </AuthenticatedRoute>
            </AuthProvider>
          }
        />
        <Route
          path="/change-lock"
          element={
            <AuthProvider>
              <AuthenticatedRoute>
                <ChangeLock />
              </AuthenticatedRoute>
            </AuthProvider>
          }
        />
        <Route
          path="/get-token"
          element={
            <AuthProvider>
              <AuthenticatedRoute>
                <DisplayToken />
              </AuthenticatedRoute>
            </AuthProvider>
          }
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
