import { AUTH_TOKENS } from "./constants";

export const decodeFirebaseIdToken = (token) => {
  try {
    const tokenParts = token.split(".");
    const payload = JSON.parse(atob(tokenParts[1]));
    return payload;
  } catch (error) {
    console.error("Error decoding Firebase ID token:", error);
    return null;
  }
};
export const getAuthToken = (tokenName) => {
  return getCookie(tokenName);
};

export const deleteAllAuthToken = () => {
  deleteCookie(AUTH_TOKENS.ID_TOKEN);
  deleteCookie(AUTH_TOKENS.REFRESH_TOKEN);
};

export const getCookie = (name) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(name + "="));

  if (cookieValue) {
    return decodeURIComponent(cookieValue.split("=")[1]);
  }

  return null;
};

function deleteCookie(name) {
  const existingCookie = getCookie(name);
  if (existingCookie) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${
      existingCookie.path || "/"
    }; domain=${existingCookie.domain || ".ignitesol.com"};`;
  }
}

export function setCookie(name, value, daysToExpire, domain) {
  const expires = new Date();
  expires.setTime(expires.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expiresUTC = expires.toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expiresUTC}; path=/; domain=${domain || document.domain}`;
}
