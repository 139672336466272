import React, { useContext } from "react";
import { AddSymbol, AddSymbolWhite, LockIcon } from "../../assets";
import { CalendarContext } from "../../contexts/CalendarContext";
import { ENTRYFORM_TAB } from "../../utils/constants";
import { gradientHours, dateToDashString } from "../../utils/helpers";
import DisplayEvent from "./DisplayEvent";

const FormatDay = ({ day, month, year, highlightWeekends, lockDate }) => {
  const {
    currentDate,
    holidayList,
    updateProjectEditInfo,
    updateCurrentDate,
    updateSelectedTab,
    resetEntryForm,
    events,
    updateEntryModal,
  } = useContext(CalendarContext);

  let hoursPerDay = 0;
  const holiday = "HOLIDAY";
  const todayDate = new Date();
  const b2 = new Date(year, month, day);
  const convertedB2 = dateToDashString(b2);

  const eventsPerDay = events.filter(
    (event) => event.entry_date === convertedB2
  );

  for (let i = 0; i < eventsPerDay.length; i++) {
    const num = parseFloat(eventsPerDay[i].hours);
    hoursPerDay += num;
  }
  hoursPerDay = hoursPerDay.toFixed(2);

  const isCurrDayHoliday =
    holidayList.filter((holiday) => holiday.date === convertedB2).length > 0;

  if (day !== 0) {
    let holidayName = "";
    if (isCurrDayHoliday) {
      holidayName = holidayList.filter(
        (holiday) => holiday.date === convertedB2
      )[0].name;
    }

    const a1 = lockDate;

    const offDay = highlightWeekends.includes(day) || isCurrDayHoliday;
    const isToday = convertedB2 === dateToDashString(todayDate);
    const isDateSelected = convertedB2 === dateToDashString(currentDate);

    let dateClass = "date";
    if (isDateSelected) {
      dateClass = "selected-date";
    } else if (isToday) {
      dateClass = "date1";
    }

    if (day !== 0 && b2 > a1 && b2 <= todayDate) {
      return (
        <td>
          <div
            className="table-cell"
            style={{ backgroundColor: offDay ? "#F5F5F5" : "" }}
          >
            <a
              onClick={() => {
                updateCurrentDate(b2);
                updateSelectedTab(ENTRYFORM_TAB.PROJECT);
                resetEntryForm();
              }}
            >
              <span id={b2} className={dateClass}>
                {day.toString().padStart(2, "0")}
              </span>
              {isCurrDayHoliday && (
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title={holidayName}
                  className="holiday"
                >
                  {holiday}
                </span>
              )}
              {!offDay && hoursPerDay > 0 && (
                <span
                  className="table-cell-hours"
                  style={{ backgroundColor: gradientHours(hoursPerDay) }}
                >
                  {hoursPerDay}
                </span>
              )}
            </a>
            <div>
              <DisplayEvent
                eventsList={eventsPerDay}
                locked={false}
                onEventClick={(event) => {
                  updateEntryModal(true);
                  updateCurrentDate(b2);
                  updateProjectEditInfo(event);
                }}
                onAddButtonClick={() => {
                  updateEntryModal(true);
                  updateCurrentDate(b2);
                  updateSelectedTab(ENTRYFORM_TAB.PROJECT);
                  resetEntryForm();
                }}
                eventDay={b2}
              />
              <span
                className="add-symbol"
                onClick={() => {
                  updateEntryModal(true);
                  updateCurrentDate(b2);
                  updateSelectedTab(ENTRYFORM_TAB.PROJECT);
                  resetEntryForm();
                }}
              >
                <AddSymbol height="15" className="first-icon" />
                <AddSymbolWhite height="15" className="second-icon" />
              </span>
            </div>
          </div>
        </td>
      );
    } else if (day !== 0) {
      // readonly day
      return (
        <td>
          <div
            className="table-cell"
            style={{
              backgroundColor: offDay ? "#F5F5F5" : "",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <a>
              <span id={b2} className={dateClass}>
                {day.toString().padStart(2, "0")}
              </span>
              {isCurrDayHoliday && (
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title={holidayName}
                  className="holiday"
                >
                  {holiday}
                </span>
              )}
              {!offDay && hoursPerDay > 0 && (
                <span
                  className="table-cell-hours"
                  style={{ backgroundColor: gradientHours(hoursPerDay) }}
                >
                  {hoursPerDay}
                </span>
              )}
            </a>
            <div>
              <DisplayEvent
                eventsList={eventsPerDay}
                locked={b2 > todayDate ? false : true}
                onEventClick={(event) => {
                  updateEntryModal(true);
                  updateCurrentDate(b2);
                  updateProjectEditInfo(event);
                }}
                onAddButtonClick={() => {}}
                eventDay={b2}
              />
              {b2 < todayDate ? (
                <span className="add-symbol">
                  <LockIcon height="15" className="first-icon" />
                  <LockIcon height="15" className="second-icon" />
                </span>
              ) : (
                <span
                  className="add-symbol"
                  onClick={() => {
                    updateEntryModal(true);
                    updateCurrentDate(b2);
                    updateSelectedTab(ENTRYFORM_TAB.LEAVE);
                    resetEntryForm();
                  }}
                >
                  <AddSymbol height="15" className="first-icon" />
                  <AddSymbolWhite height="15" className="second-icon" />
                </span>
              )}
            </div>
          </div>
        </td>
      );
    }
  }
  return (
    <td>
      <div className="table-cell"></div>
    </td>
  );
};

export default FormatDay;
