import React from 'react';

function TSButton({
  label,
  value,
  onClick,
  secondary = false,
  disabled = false,
}) {
  return (
    <button
      type="submit"
      className={`${secondary ? "btn btn-secondary " : "button button1"}`}
      value={value}
      onClick={onClick}
      style={{
        height: "40px",
        marginRight: "8px",
        borderRadius: "12px",
        fontSize: "18px",
        fontFamily: "AktivGrotesk",
        fontWeight: "600",
        padding: "4px 18px",
      }}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export default TSButton;
