import { Header } from 'components'
import { AuthProvider } from 'contexts/AuthContext'
import { CalendarContextProvider } from 'contexts/CalendarContext'
import React from 'react'
import { Outlet } from 'react-router-dom'
import AuthenticatedRoute from 'utils/AuthenticatedRoute'

function CalendarLayout({ children }) {
    return (
        <div>
            <div className='show-desktop'>
                <Header />
                <div className='container page-container'>
                    {children}
                </div>
            </div>
            <div className='show-mobile'>
                <div className='not-found'>
                    <img
                        src="/images/ignite.png"
                        width="252"
                        height="102"
                        alt="Ignite"
                    />
                    <h5><a href='https://ignitesol.app.link/timesheet' style={{ color: 'blue' }}>DOWNLOAD APP</a></h5>
                </div>
            </div>
        </div>
    )
}

export default CalendarLayout