import React, { useEffect, useState, useRef, useContext } from "react";
import { AddSymbol, AddSymbolWhite, LockIcon } from "../../assets";
import { CalendarContext } from "contexts/CalendarContext";
import { dateToDashString } from "utils/helpers";

const DisplayEvent = ({
  locked,
  eventsList,
  onEventClick,
  onAddButtonClick,
  eventDay
}) => {
  const { currentDate, updateCurrentDate } = useContext(CalendarContext);
  const [showEvent, setShowEvent] = useState([]);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [actionTaken, setActionTaken] = useState(false)
  const [modalStyle, setModalStyle] = useState({});
  const divRef = useRef(null);


  useEffect(() => {
    if (eventsList.length > 3) {
      setShowEvent(eventsList.slice(0, 3));
    } else {
      setShowEvent(eventsList);
    }
    if (divRef.current) {
      const divRect = divRef.current.getBoundingClientRect();
      setModalStyle(divRect);
    }
  }, [eventsList]);

  useEffect(() => {
    if (currentDate.getTime() === eventDay.getTime() && actionTaken) {
      setOpenEventModal(true)
    } else {
      setOpenEventModal(false)
      setActionTaken(false)
    }
  }, [currentDate]);

  const formatEntryDate = (date) => {
    if (date) {

      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return ''
  }

  return (
    <React.Fragment>
      <ul className="event_list" ref={divRef}>
        {showEvent.map((event) => {
          return (
            <li
              key={event.id}
              title={`${event.workstream} \n ${event.task_title ?? ""} \n ${
                event.description ?? ""
              }`}
              onClick={() => onEventClick(event)}
            >
              {`${event.hours}  ${event.project}`}
            </li>
          );
        })}
        {eventsList.length > 3 && (
          <a
            className="small-text bold"
            onClick={() => {
              setActionTaken(true)
              updateCurrentDate(eventDay)
            }}
          >{`+${eventsList.length - 3} More`}</a>
        )}
      </ul>
      <div
        className={`modal ${openEventModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{
          display: openEventModal ? "block" : "none",
          width: "230px",
          top: `${modalStyle.top}px`,
          left: `${modalStyle.left}px`,
          height: 'auto',
          position: 'absolute'
        }}
        id={eventDay}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content"
            style={{
              boxShadow: "4px 4px 12px 0px #0000001F",
              border: "1px solid #DEDEDE",
              borderRadius: "12px",
            }}
          >
            <div className="modal-header" style={{ borderBottom: "none" }}>
              <span className="small-text bold">{formatEntryDate(eventDay)}</span>
              <button
                type="button"
                className="close"
                onClick={() => {
                  setActionTaken(false)
                  setOpenEventModal(false)
                }}
                style={{ position: "absolute", right: "30px" }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                padding: "1rem 1rem 1rem 1rem",
                maxHeight: "220px",
                overflow: "auto",
              }}
            >
              <div className="center-flex">
                <ul style={{ width: "70%", marginRight: "10px" }}>
                  {eventsList.map((event) => {
                    return (
                      <li
                        key={event.id}
                        title={`${event.workstream} \n ${
                          event.task_title ?? ""
                        } \n ${event.description ?? ""}`}
                        onClick={() => onEventClick(event)}
                      >
                        {`${event.hours}  ${event.project}`}
                      </li>
                    );
                  })}
                </ul>
                {!locked && (
                  <span
                    className="add-symbol"
                    onClick={onAddButtonClick}
                    style={{ marginBottom: "16px" }}
                  >
                    <AddSymbol height="15" className="first-icon" />
                    <AddSymbolWhite height="15" className="second-icon" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DisplayEvent;
