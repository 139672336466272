import TSButton from "components/TSButton/TSButton";
import React from "react";

function Modal({ open, message, isConfirmModal = false, onSuccess, onReject }) {
  return (
    <div
      className={`${open ? "show show-modal" : ""} fade`}
      id="memberModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="memberModalLabel"
      aria-hidden={!open}
      style={{ display: open ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content medium-text"
          style={{
            boxShadow: "4px 4px 12px 0px #0000001F",
            border: "1px solid #DEDEDE",
            borderRadius: "12px",
          }}
        >
          <div className="modal-header">{message}</div>
          <div style={{ textAlign: "center", padding: "12px 0px" }}>
            <TSButton
              label={isConfirmModal ? "YES" : "OK"}
              value={isConfirmModal ? "YES" : "OK"}
              onClick={onSuccess}
            />

            {isConfirmModal && (
              <span style={{ marginLeft: "8px" }}>
                <TSButton
                  type="button"
                  label={"NO"}
                  value={"NO"}
                  onClick={onReject}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
