import TSButton from "components/TSButton/TSButton";
import React, { useState } from "react";
import { postAllowEditRequest, getEntryRequestsCount } from "services/api";
import TSToast from "./TSToast";
import { useEffect } from "react";

const intialValidation = {
  requestReason: false,
};
function EntryRequestModal({ openModal, onClose }) {
  const [showToast, setShowToast] = useState(false);
  const [requestReason, setRequestReason] = useState("");
  const [validationError, setValidationError] = useState(intialValidation);
  const [totalRequests, setTotalRequests] = useState(0);
  const [requestReasonLength, setRequestReasonLength] = useState(25);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    getEntryRequestsCount().then((res) => {
      setTotalRequests(res.data);
    });
  }, []);

  useEffect(() => {
    setRequestReasonLength(25 + 10 * totalRequests);
  }, [totalRequests]);

  const handleEditRequest = () => {
    if (
      !validationError.requestReason &&
      requestReason.length >= requestReasonLength
    ) {
      postAllowEditRequest({
        reason_for_entry: requestReason,
      })
        .then((res) => {
          setRequestReason("");
          setValidationError(intialValidation);
          setShowToast(true);
          onClose();
        })
        .catch((error) => console.error(error));
    } else {
      handleValidationError("requestReason", requestReason);
    }
  };

  const handleValidationError = (field, value) => {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: !value || value.length < requestReasonLength ? true : false,
    }));
  };

  return (
    <React.Fragment>
      <div
        className={`${openModal ? "show show-modal" : ""} fade`}
        id="EditAccessModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditAccessModal"
        aria-hidden={!openModal}
        style={{ display: openModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document" style={{ top: "15%" }}>
          <div
            className="modal-content"
            style={{
              boxShadow: "4px 4px 12px 0px #0000001F",
              border: "1px solid #DEDEDE",
              borderRadius: "12px",
              minHeight: "600px",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">REQUEST EDIT ACCESS</h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                padding: "1rem 1rem 1rem 1rem",
                maxHeight: "480px",
                overflow: "auto",
              }}
            >
              <div className="center-flex">
                <div className="entry-input select-full-width">
                  <textarea
                    name="requestReason"
                    rows="10"
                    className={`form-control ${
                      validationError.requestReason ? "is-invalid" : ""
                    }`}
                    placeholder="Enter reason for missing entries."
                    type="text"
                    minLength={requestReasonLength}
                    value={requestReason}
                    onChange={(e) => {
                      setRequestReason(e.target.value);
                      handleValidationError("requestReason", e.target.value);
                    }}
                    onBlur={() =>
                      handleValidationError("requestReason", requestReason)
                    }
                  ></textarea>
                  <div className="invalid-feedback">
                    Please enter a reason in the textarea with minimum{" "}
                    {requestReasonLength} characters.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <TSButton
                label="REQUEST"
                value="Request"
                onClick={handleEditRequest}
              />
            </div>
          </div>
        </div>
      </div>
      <TSToast
        message={`Request for edit access is sent!`}
        open={showToast}
        isHeader={false}
        onClose={() => setShowToast(false)}
      />
    </React.Fragment>
  );
}

export default EntryRequestModal;
