import { TIME_HOURS_GRADIANT } from './constants';

export const dateToDashString = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const dashStringToDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return new Date(year, month - 1, day);
};

export const gradientHours = (inputHours) => {
  const hours = parseInt(inputHours);
  let color = '';

  if (hours && hours <= 8) {
    color = TIME_HOURS_GRADIANT[hours - 1].toLowerCase();
  } else if (hours && hours > 12) {
    color = "#dc3545";
  } else if (hours && hours > 8) {
    color = "darkgreen";
  } else {
    color = "#DE532E";
  }
  return color;
};
export const getInitialDate = (month, year) => {
  const initialDate = new Date(year, month - 1, 1);
  console.log(initialDate, 'initial Date');
  return initialDate;
};

export const getWeekends = (month, year) => {
  const highlight_weekends = [];
  const days_in_month = new Date(year, month + 1, 0).getDate();
  let firstDate = new Date(year, month, 1);
  const last_date = new Date(year, month, days_in_month);
  const diff = (last_date - firstDate) / (1000 * 60 * 60 * 24) + 1;

  for (let i = 0; i < diff; i++) {
    const dayOfWeek = (firstDate.getDay() + 6) % 7; // Adjusted for Monday as the start of the week
    if (dayOfWeek === 5 || dayOfWeek === 6) {
      // Saturday (5) and Sunday (6)
      highlight_weekends.push(firstDate.getDate());
    }
    firstDate.setDate(firstDate.getDate() + 1);
  }
  return highlight_weekends;
};

export const getLockedDate = () => {
  const currentDate = new Date();
  const previousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const previousMonthFirstDay = previousMonth;
  return previousMonthFirstDay;
};

export const Formatweekheader = () => {
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  return (
    <thead>
      <tr>
        {weekdays.map((weekday) => (
          <th key={weekday}>{weekday}</th>
        ))}
      </tr>
    </thead>
  );
};

export const getFirst = (obj, key) => {
  if (obj && typeof obj === 'object' && key in obj) {
    return obj[key];
  }
  return [];
};

export const getCurrentUserName = (email) => {
  return email.split('@')[0];
};

export const isDateHoliday = (date, holidayList) => {
  const dayOfWeek = date.getDay();
  return (
    dayOfWeek === 0 ||
    dayOfWeek === 6 ||
    holidayList.some((holiday) => holiday.date === dateToDashString(date))
  );
};

export const checkHolidayBetweenDates = (holidayList, startDate, endDate) => {
  let start = dashStringToDate(startDate);
  let end = dashStringToDate(endDate);

  let currentDate = start;
  while (currentDate <= end) {
    if (!isDateHoliday(currentDate, holidayList)) {
      return false;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return true;
};



export const monthdays2calendar = (year, month) => {
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const firstWeekday = (firstDay.getDay() + 6) % 7;
  const daysInMonth = lastDay.getDate();

  const weeks = [];
  let week = [];

  for (let i = 0; i < firstWeekday; i++) {
    week.push([0, null]);
  }

  for (let day = 1; day <= daysInMonth; day++) {
    week.push([day, new Date(year, month, day)]);
    if (week.length === 7) {
      weeks.push(week);
      week = [];
    }
  }

  const lastWeekday = (lastDay.getDay() + 6) % 7;
  if (week.length > 0) {
    for (let i = week.length; i < 7; i++) {
      week.push([0, null]);
    }
  }

  if (week.length > 0) {
    weeks.push(week);
  }

  return weeks;
};
