import React, { useContext, useState, useEffect } from 'react';
import { getCurrentUserName } from 'utils/helpers';
import { decodeFirebaseIdToken, getAuthToken } from "utils/authToken";
import { AUTH_TOKENS } from "utils/constants";

const AuthContext = React.createContext();

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useContext Not Defined!");
  }
  return context;
};

export function AuthProvider({ children }) {
  let initUserData = {};
  if (getAuthToken(AUTH_TOKENS.ID_TOKEN)) {
    initUserData = decodeFirebaseIdToken(getAuthToken(AUTH_TOKENS.ID_TOKEN));
    initUserData = {
      ...initUserData,
      userName: getCurrentUserName(initUserData.email),
    };
  }
  const [isAuthenticated, setAuthenticated] = useState(
    !!getAuthToken(AUTH_TOKENS.ID_TOKEN)
  );
  const [currentUser, setCurrentUser] = useState(initUserData);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAuthenticated,
        setAuthenticated,
        setCurrentUser,
      }}
    >
      {currentUser && children}
    </AuthContext.Provider>
  );
}

export { useAuthContext, AuthContext };
