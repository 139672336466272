export const ENTRYFORM_TAB = {
    PROJECT: 'PROJECT',
    LEAVE: 'LEAVE',
};

export const APP_TOKEN = 'token';

export const AUTH_TOKENS = {
    REFRESH_TOKEN: "refreshToken",
    ID_TOKEN: "idToken",
};
export const PROJECT_TAG_COUNT = 3;

export const MODAL_MESSAGES = {
    DELETE_WARNING: "Are you sure you want to delete the time entry?",
    TASK_WARNING: "Either 'Task' or 'What did you work on?' needs to be filled",
    DATE_WARNING: "Please select a 'Date'",
    HOURS_WARNING: "'Hours' needs to be greater than 0 and less than 24",
    PROJECT_WARNING: "Please select a 'Project'",
    WORKSTREAM_WARNING: "Please select a 'Workstream'",
    LEAVE_REASON_WARNING: "Please enter reason for leave",
    LEAVE_TYPE_WARNING: "Please select either 'Personal' or 'Sick' leave",
    HOLIDAY_WARNING: "You already have a holiday for the date selection",
    DATE_ENTRY_WARNING: "This entry cannot be modified. Please contact the Admin.",
};

export const TIME_HOURS_GRADIANT = ['#DE532E', '#D47D36', '#C69F34', '#BFBF41', '#8DAF36', '#75A944', '#4B8F29', '#387E21']