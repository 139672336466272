import { TSButton } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/igntime');
  };
  return (
    <div className="not-found">
      <h1>404 | Page Not Found</h1>
      <div>
        <TSButton value="back" label="Go Back" onClick={handleGoBack} />
      </div>
    </div>
  );
};

export default NotFound;
