import React, { useContext, useEffect, useState, useRef } from "react";
import { AddSymbol, AddSymbolWhite, LockIcon } from "../../assets";
import { CalendarContext } from "../../contexts/CalendarContext";
import { ENTRYFORM_TAB } from "../../utils/constants";
import {
  gradientHours,
  Formatweekheader,
  getWeekends,
  monthdays2calendar,
  dateToDashString,
} from "../../utils/helpers";
import FormatDay from "./FormatDay";

const Calendar = ({ month, year, lockDate, Holidays = [] }) => {
  const highlightWeekends = getWeekends(month, year);

  return (
    <table
      border="0"
      cellPadding="0"
      cellSpacing="0"
      className="calendar"
      style={{ minWidth: "500px" }}
    >
      <Formatweekheader />
      <tbody>
        {monthdays2calendar(year, month).map((week, windex) => {
          return (
            <tr key={windex}>
              {week.map(([d, weekday], index) => (
                <FormatDay
                  key={`${d}-${index}-${windex}-${month}-${year}`}
                  day={d}
                  month={month}
                  year={year}
                  holidays={Holidays}
                  highlightWeekends={highlightWeekends}
                  lockDate={lockDate}
                />
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Calendar;


