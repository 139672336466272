import React, { useEffect, useState } from 'react';
import { getNewToken } from 'services/api';

export default function DisplayToken() {
  const [token, setToken] = useState('');

  useEffect(() => {
    getNewToken()
      .then((res) => {
        if (res && res.data) {
          setToken(res.data.token);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-8">
        <div className="card">
          <div className="card-body">
            <h3 className="header-3">Your Token is: {token} </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
