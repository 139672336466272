import React, { useEffect, useRef, onClose } from "react";

function TSToast({ open, title, message, isHeader = true, onClose }) {
  const toastRef = useRef(null);

  useEffect(() => {
    if (open) {
      const toastElement = toastRef.current;
      const toast = new window.bootstrap.Toast(toastElement, {
        autohide: true,
        delay: 4000,
      });
      setTimeout(() => {
        onClose();
      }, 4000);
      toast.show();
      return () => {
        toast.dispose();
      };
    }
  }, [open]);

  return (
    <div
      ref={toastRef}
      className={`toast ${open ? "show" : ""}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{
        position: "fixed",
        bottom: "100px",
        left: "50%",
        transform: "translateX(-50%)",
        minWidth: "200px",
        fontSize: "16px",
        fontFamily: "AktivGrotesk",
        boxShadow: "4px 4px 12px 0px #0000001F",
        border: "1px solid #DEDEDE",
        borderRadius: "12px",
      }}
    >
      {isHeader && (
        <div className="toast-header">
          <strong className="me-auto">{title}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </div>
      )}
      <div className="toast-body">{message}</div>
    </div>
  );
}

export default TSToast;
